
/**
 * @name: 视频上传组件
 * @author: itmobai
 * @date: 2023-03-17 17:24
 * @description：视频上传组件
 * @update: 2023-03-17 17:24
 */
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import config from "@/config";
import type {IConfig} from "@/config/types";
import {getToken} from "@/utils/token";

@Component({name: "VideoUpload"})
export default class VideoUpload extends Vue {
  // v-model
  @Prop(String) value!: string;
  // 上传类型
  @Prop({default: 1}) type!: number;
  // 是否支持多选
  @Prop({default: false}) multiple!: boolean;
  // 上传数量
  @Prop({default: 1}) limit!: number;
  // 上传前函数
  @Prop({
    default: () => {
    }
  })
  beforeUpload!: () => {
  }

  @Watch("value", {immediate: true})
  onValueChange(newVal: string) {
    if (newVal) {
      this.videoForm.showVideoPath=location.origin+config.videoDownloadUrl+newVal;
      let arr = newVal.split(";")
      this.filelist = arr.map(item => {
        return {
          url: item
        }
      })
    } else {
      this.filelist = []
      this.videoForm.showVideoPath = ''
    }
  }

  // 请求配置
  config: IConfig = config
  // 请求头token配置
  headers = {
    "Authorization": `Bearer ${getToken()}`
  }
  // 真实url集合
  filelist: { url: string }[] = []

  //url
  videoForm={
    showVideoPath: "",  //回显的变量
  }
  isLoading=false
  percent=0;
  // 预览列表
  get previewList() {
    return this.filelist.map(item => {
      return {
        url: config.videoDownloadUrl + item.url
      }
    })
  }

  /**
   * 上传成功事件
   * @param response
   * @param file
   * @param fileList
   */
  onUploadSuccess(response: any, file: File, fileList: any) {
    if (response && response.code == 0) {
      let {url, fileName} = response.data
      this.filelist = [{url: url}]
      this.videoForm.showVideoPath=location.origin+config.videoDownloadUrl+url;
      this.$emit("input", this.filelist.map(item => item.url).join(";"))
      this.isLoading=false;
    }
  }

  /**
   * 文件移除时
   * @param file
   * @param fileList
   */
  onUploadRemove(file: any, fileList: any) {
    this.filelist = this.filelist.filter(item => file.url.indexOf(item.url) == -1)
    this.$emit("input", this.filelist.map(item => item.url).join(";"))
  }

  /**
   * 文件上传进度
   * @param event
   * @param file
   * @param fileList
   */
  onUploadProgress(event: any, file: any, fileList: any){
    this.percent=event.percent;
    if(event.percent<100){
      this.isLoading=true;
    }
  }
}
