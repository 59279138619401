
/**
 * @name: 附件上传
 * @author: itmobai
 * @date: 2023-03-21 17:07
 * @description：附件上传
 * @update: 2023-03-21 17:07
 */
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import config from "@/config";
import type {IConfig} from "@/config/types";
import {getToken} from "@/utils/token";

@Component({name: "FileUpload"})
export default class FileUpload extends Vue {
  // v-model
  @Prop(String) value!: string;
  // 上传类型
  @Prop({default: 1}) type!: number;
  // 是否支持多选
  @Prop({default: false}) multiple!: boolean;
  // 上传数量
  @Prop({default: 1}) limit!: number;
  // 上传前函数
  @Prop({
    default: () => {
    }
  }) beforeUpload!: () => {}

  @Watch("value", {immediate: true})
  onValueChange(newVal: string) {
    if (newVal) {
      let arr = newVal.split(";")
      this.filelist = arr.map(item => {
        return {
          url: item,
          name: item.split('/').pop() as string
        }
      })
    } else {
      this.filelist = []
    }
  }

  // 请求配置
  config: IConfig = config
  // 请求头token配置
  headers = {
    "Authorization": `Bearer ${getToken()}`
  }
  // 真实url集合
  filelist: { url: string, name: string }[] = []

  // 预览列表
  get previewList() {
    return this.filelist.map(item => {
      return {
        url: config.downloadUrl + item.url,
        name: item.url.split('/').pop() as string
      }
    })
  }

  /**
   * 上传成功事件
   * @param response
   * @param file
   * @param fileList
   */
  onUploadSuccess(response: any, file: File, fileList: any) {
    if (response && response.code == 0) {
      let {url, fileName} = response.data
      this.filelist.push({name: url.split('/').pop(), url: url})
      this.$emit("input", this.filelist.map(item => item.url).join(";"))
      this.$emit("getFileName",fileName )
    }
  }

  // 文件个数超出
  handleExceed() {
    this.$message.error(`上传文件数量不能超过 ${this.limit} 个!`);
  }
  /**
   * 文件移除时
   * @param file
   * @param fileList
   */
  onUploadRemove(file: any, fileList: any) {
    this.filelist = this.filelist.filter(item => file.url.indexOf(item.url) == -1)
    this.$emit("input", this.filelist.map(item => item.url).join(";"))
  }
}
